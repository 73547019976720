const path = require('path');

const alias = require('./webpack-alias');
const rssConfig = require('./rss-plugin-config');

module.exports = {
  siteMetadata: {
    title: "Adam Napso's Blog",
    author: 'Adam napso',
    description:
      'Personal blog of Adam napso.',
    siteUrl: 'https://www.nap.so',
    social: {
      twitter: '@adnapso',
    },
  },
  plugins: [
    // rssConfig,

    {
      resolve: `gatsby-plugin-alias-imports`,
      options: {
        alias,
        extensions: [],
      },
    },
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-flow',
    'gatsby-plugin-styled-components',
    {
      resolve: `gatsby-plugin-mdx`,
      options: {
        defaultLayouts: {
          posts: require.resolve('./src/components/BlogPost/BlogPost.js'),
        },
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'posts',
        path: `${__dirname}/src/pages/posts`,
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'images',
        path: path.join(__dirname, 'src', 'assets', 'images'),
      },
    },
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: 'UA-36404651-7',
        cookieExpires: 63072000, // two years
      },
    },
  ],
};
